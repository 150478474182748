<template>
  <Form class="text-center" ref="form" @submit="changePassword">
    <v-row justify="center">
      <v-col cols="12" sm="8" lg="6">
        <TextField
          v-model="form.current_password"
          label="Contraseña actual"
          prepend-icon="mdi-lock"
          ref="currentPassword"
          rules="required"
          :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showCurrentPassword ? 'text' : 'password'"
          @click:append="showCurrentPassword = !showCurrentPassword"
        />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="8" lg="6">
        <TextField
          v-model="form.new_password"
          label="Contraseña nueva"
          prepend-icon="mdi-lock"
          rules="required"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPassword ? 'text' : 'password'"
          @click:append="showNewPassword = !showNewPassword"
        />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12">
        <v-btn class="px-10 mb-3" color="primary" type="submit">
          <v-icon>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
// API
import AuthAPI from '@/api/auth';

const initialForm = () => ({
  current_password: null,
  new_password: null,
});

export default {
  name: 'ChangePassword',

  data: () => ({
    form: initialForm(),
    showCurrentPassword: false,
    showNewPassword: false,
  }),

  created() {
    this.api = new AuthAPI();
  },

  methods: {
    async changePassword() {
      this.$store.commit('showLoader');
      try {
        await this.api.changePassword(this.form);
        this.form = initialForm();
        this.$refs.form.reset();
        this.$store.dispatch('notification/notifySuccess', 'Contraseña cambiada');
      } catch (error) {
        const errorMessage = error.data?.non_field_errors?.[0];
        this.$store.dispatch('notification/notifyError', errorMessage);

        if (errorMessage) {
          this.$refs.currentPassword.setErrors([
            'Contraseña incorrecta',
          ]);
        }
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped></style>
