<template>
  <PersonForm :person="person" @submit="updateProfile" />
</template>

<script>
// API
import AuthAPI from '@/api/auth';

// Components
import PersonForm from '@/components/person/Form.vue';

// Utils
import { initialPerson } from '@/utils/person';

export default {
  name: 'Profile',

  components: {
    PersonForm,
  },

  data: () => ({
    person: initialPerson(),
  }),

  async created() {
    this.api = new AuthAPI();
    await this.getProfile();
  },

  methods: {
    async getProfile() {
      this.$store.commit('showLoader');
      try {
        const profile = await this.api.profile();
        const { address } = initialPerson();

        // Set initial address object when profile's address is null.
        if (!profile.address) {
          profile.address = address;
        }

        this.person = profile;
        this.person.role = profile.user.role;
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async updateProfile() {
      this.$store.commit('showLoader');
      try {
        this.profile = await this.api.updateProfile(this.person);
        this.$store.commit('auth/setProfile', this.profile);
        this.$store.dispatch('notification/notifySuccess');

        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        }
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
