<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-space-between flex-wrap">
        <h1>Account</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-tabs v-model="tab" fixed-tabs>
          <v-tab>
            <v-icon>mdi-account</v-icon>
            Perfil
          </v-tab>

          <v-tab>
            <v-icon>mdi-lock</v-icon>
            Contraseña
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Profile />
          </v-tab-item>

          <v-tab-item>
            <ChangePassword />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Components
import ChangePassword from './components/ChangePassword.vue';
import Profile from './components/Profile.vue';

export default {
  name: 'Account',

  components: {
    ChangePassword,
    Profile,
  },

  data: () => ({
    tab: 0,
  }),
};
</script>

<style lang="scss" scoped></style>
